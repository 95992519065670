import React from 'react';
import GlobalStyled from '../../Solucoes.styled';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import Overview from 'pages/solucoes/components/generics/Overview';
import InPractice, { TypeCard } from 'pages/solucoes/components/generics/InPractice';
import SupportMaterial from 'pages/solucoes/components/generics/SupportMaterial';
import FormSolucoes from 'pages/solucoes/components/solucoes/FormSolucoes';
import TipoSolucoesEnum from 'enums/TipoSolucoesEnum';
import Imagem from '../../assets/seLiga/logo.png';

//Material
import imagem_01 from '../../assets/seLiga/material/imagem_01.jpg';

//Material InPratice
import imagem_inpratice_01 from '../../assets/seLiga/inPratice/img_01.jpg';
import imagem_inpratice_02 from '../../assets/seLiga/inPratice/img_02.jpg';
import imagem_inpratice_03 from '../../assets/seLiga/inPratice/img_03.jpg';
import imagem_inpratice_04 from '../../assets/seLiga/inPratice/img_04.jpg';
import imagem_inpratice_05 from '../../assets/seLiga/inPratice/img_05.jpg';
import imagem_inpratice_06 from '../../assets/seLiga/inPratice/img_06.jpg';

import ContactContainer from 'pages/home/components/contact/ContactContainer';

const SeLiga = () => {
    return (
        <div>
            <DynamicHeader />
            <GlobalStyled.Container>
                <Overview
                    dados={{
                        title: 'Se Liga',
                        descricao: `
                            O Instituto Ayrton Senna criou o Se Liga em 2001, a partir das experiências vividas com a solução educacional Acelera Brasil, lançada em 1997, 
                            para dar conta do desafio de combater a distorção idade-ano e corrigir o fluxo de alunos ainda não alfabetizados. A solução educacional passou por um processo de atualização em 2023, 
                            considerando o cenário atual e a integração com a BNCC.
                        `,
                        objetivo: `
                         O objetivo do Se Liga é alfabetizar alunos com distorção idade-ano,
                        matriculados nas turmas do 3º ao 5º ano do EF, oferecendo-lhes condições necessárias
                        para o prosseguimento no fluxo escolar.
                         `,
                        publicoAlvo: '3º ao 5º do Ensino Fundamental.',
                        avaliacao: 'Testes Diagnósticos de Alfabetização (enturmação).',
                        formacao: `
                        Encontros formativos presenciais para professores e mediadores (carga
                        horária – 24 horas).
                        `,
                        acompanhamento: `
                            Dados quantitativos e qualitativos, ancorados em indicadores e
                            metas previamente definidas.
                        `,
                        atores: `
                        Estudante, Professor (gestão da sala de aula: ensino/ aprendizagem),
                            Coordenador Pedagógico / Diretor (gestão da rotina escolar), Secretaria de Educação
                            (gestão da política educacional).
                        `,
                        elementos: `
                           (I) Material didático do aluno e do professor, com fundamentos da
                            proposta e orientações específicas para o trabalho aula a aula, e base em currículo
                            para promover o desenvolvimento integral; (II) Coordenação e gerenciamento; (III)
                            Formação presencial e a distância (EAD), inicial e continuada; (IV) Sistema de
                            monitoramento e avaliação, gerenciamento dos indicadores de aprendizagem; e (V)
                            Apoio de agentes técnicos.
                        
                        `,
                        img: Imagem,
                    }}
                />

                <InPractice
                    items={[
                        {
                            type: TypeCard.VIDEO,
                            url: '65_4pMkpYrs',
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_01,
                            credits: {
                                credits: 'Bruno Kelly',
                                local: 'Manaus',
                                ano: '2019',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_02,
                            credits: {
                                credits: 'Bruno Kelly',
                                local: 'Manaus',
                                ano: '2019',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_03,
                            credits: {
                                credits: 'Bruno Kelly',
                                local: 'Manaus',
                                ano: '2019',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_04,
                            credits: {
                                credits: 'Bruno Kelly',
                                local: 'Manaus',
                                ano: '2019',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_06,
                            credits: {
                                credits: 'Flávio Araújo',
                                local: 'Santarém',
                                ano: '2018',
                            },
                        },
                    ]}
                    description={`
                    Quer conhecer um pouco mais sobre como o Se Liga acontece na prática?<br/><br/>
                    Navegue pelas imagens e vídeos e conheça algumas das redes onde a solução é implementada atualmente.<br/><br/>
                    Sua rede pode ser a próxima a implementar a solução Se Liga e ter as imagens compartilhadas aqui na humane. Que tal? 
                    `}
                />
                <SupportMaterial
                    isBorderBlue
                    description="
                    No Se Liga, o professor recebe três livros: Português, Matemática e o Livro do professor e os estudantes recebem dois livros: Língua Portuguesa e Matemática. Também são disponibilizados: 
                   Subsídios para o professor para o período que antecede o início das aulas; 
                   Orientações para aplicação de Teste Diagnóstico e Sistemática de Acompanhamento para monitorar e gerenciar o ensino e a aprendizagem por meio de dados.
                "
                    img={imagem_01}
                />

                <FormSolucoes isBlue tipoSolucao={TipoSolucoesEnum.SE_LIGA} preDefinition />
                <ContactContainer isLight />
            </GlobalStyled.Container>
        </div>
    );
};

export default SeLiga;
