import ABTest from 'components/ab_tests/ABTest';
import solucoesEducionaisTexts from 'components/ab_tests/solucoesEducionaisTexts';
import { GTM_Page_Home } from 'components/GoogleTagManager';
import { MobileMaxWidth } from 'constants/Dimensions';
import { caminhoAnexosHomepage, bucketS3 } from 'constants/EnvironmentData';
import AdminHomeConteudoService from 'core/http/service/admin/AdminHomeConteudo.service';
import { IsMobileMaxWidth, IsCustomMaxWidth } from 'core/util/WindowSize';
import Colors from 'enums/Colors';
import TestVersion from 'enums/TestVersions';
import EducationalSolution from 'models/EducationalSolution';
import React, { useState, useEffect, useCallback } from 'react';
import ContentLoader from 'react-content-loader';
import { useHistory } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web';

import crossIcon from '../../../../../images/cross-icon.svg';
import Img_card_1 from '../../../../../images/homepage/educationalSolutions/img_card_1.svg';
import playIcon from '../../../../../images/play-icon.svg';
import {
    Wrapper,
    Title,
    TextBox,
    TerciaryButton,
    SvgIcon,
    SecondaryButton,
    PrimaryButton,
    HoverButton,
    FooterStyle,
    DescriptionText,
    DefaultText,
    ContentStyle,
    CardTag,
    CardStyle,
    CardImgContainer,
    CardImg,
    CardTagsContainer,
    MobileCardTagsContainer,
    MobileCardTag,
} from './HoverCard.styled';

interface IHoverCard {
    educationalSolutionItem: EducationalSolution;
}

const service = new AdminHomeConteudoService();

const HoverCard = ({ educationalSolutionItem }: IHoverCard) => {
    const [image, setImage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [isHovered, setHovered] = useState<Boolean>(false);
    const { keycloak } = useKeycloak();
    const usuarioLogado = keycloak.authenticated;

    const getS3BaseUrl = (): string => {
        return bucketS3() + caminhoAnexosHomepage();
    };

    const fetchImage2 = useCallback(async () => {
        let imageUrl = '';
        if (!educationalSolutionItem.defaultData && educationalSolutionItem.id) {
            let { data, status } = await service.buscarAnexoConteudo(educationalSolutionItem.id, false);
            imageUrl = getS3BaseUrl() + data.filePath;
        }
        return imageUrl;
    }, [educationalSolutionItem]);

    const fetchImage = async () => {
        setLoading(true);
        setImage(await fetchImage2());
        setLoading(false);
    };

    useEffect(() => {
        fetchImage();
    }, [educationalSolutionItem]);

    const getText = (): string => {
        // const currentVersion = ABTest.getCurrentVersion();

        // if (currentVersion == TestVersion.VersionC) {
          const  testText = solucoesEducionaisTexts.get(educationalSolutionItem.title.toLowerCase());
        // }

        return testText ?? educationalSolutionItem.text;
    };

    const getSizeCard = (): number => {
        const currentVersion = ABTest.getCurrentVersion();
        return currentVersion == TestVersion.VersionB ? 350 : 440;
    };

    // console.log('Cod: ' + educationalSolutionItem.title + ' - filepath: ' + image);

    const history = useHistory();

    // Página Jornada
    const goToJourney = () => {
        GTM_Page_Home('secao_jornada_desenvolvimento', `btn_click_${educationalSolutionItem.title.replace(/\s+/g, '_').toLowerCase()}`);
        if (educationalSolutionItem.active) {
            if (educationalSolutionItem.title === 'Socioemocional de Professores') {
                history.push({
                    pathname: '/login',
                    state: { pathToRedirect: '/painelSocioemocional/home' },
                });
            } else if (educationalSolutionItem.title === 'Realizando Escolhas') {
                history.push({
                    pathname: '/login',
                    state: { pathToRedirect: '/realizandoEscolhas/home' },
                });
            }
        }
    };

    // Página Intermediária
    const goToDetailPage = () => {
        GTM_Page_Home('secao_jornada_desenvolvimento', `btn_click_${educationalSolutionItem.followRoute.replace('/', '').toLowerCase()}`);
        if (educationalSolutionItem.linkExterno) {
            let url = educationalSolutionItem.followRoute;
            if (!url.match(/^https?:\/\//i)) {
                url = 'https://' + url;
            }

            window.open(url, '_blank');
        } else
            history.push({
                pathname: educationalSolutionItem.followRoute,
                state: {
                    socioemocionalEducadores: true,
                },
            });
    };

    const IsMobile = () => {
        return IsMobileMaxWidth();
    };

    const MyLoader = () => {
        return IsMobile() ? (
            <>
                <ContentLoader
                    width={290}
                    height={130}
                    viewBox="0 0 290 130"
                    backgroundColor="#f0f0f0"
                    foregroundColor="#dedede"
                    title="Carregando..."
                >
                    <rect x="0" y="0" rx="0" ry="0" width="290" height="130" />
                </ContentLoader>
            </>
        ) : (
            <>
                <ContentLoader
                    width={getSizeCard()}
                    height={174}
                    viewBox={`0 0 ${getSizeCard()} 174`}
                    backgroundColor="#f0f0f0"
                    foregroundColor="#dedede"
                    title="Carregando..."
                >
                    <rect x="0" y="0" rx="0" ry="0" width="440" height="174" />
                </ContentLoader>
            </>
        );
    };
    return (
        <Wrapper>
            <CardStyle width={getSizeCard()}>
                <CardImgContainer onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                    {!loading ? (
                        <>
                            <CardImg style={isHovered ? { opacity: '0.3' } : { opacity: '1' }} src={image ?? ''} />
                        </>
                    ) : (
                        <MyLoader />
                    )}
                    {isHovered && (
                        <HoverButton
                            onClick={
                                educationalSolutionItem.title == 'Alfabetização 360°'
                                    ? goToDetailPage
                                    : usuarioLogado
                                    ? educationalSolutionItem.active
                                        ? goToJourney
                                        : goToDetailPage
                                    : goToDetailPage
                            }
                        >
                            <SvgIcon
                                src={
                                    educationalSolutionItem.title == 'Alfabetização 360°'
                                        ? crossIcon
                                        : usuarioLogado
                                        ? educationalSolutionItem.active
                                            ? playIcon
                                            : crossIcon
                                        : crossIcon
                                }
                            ></SvgIcon>
                            <DefaultText>
                                {educationalSolutionItem.title == 'Alfabetização 360°'
                                    ? 'Saiba mais'
                                    : usuarioLogado
                                    ? educationalSolutionItem.active
                                        ? 'Acessar'
                                        : 'Saiba mais'
                                    : 'Saiba mais'}
                            </DefaultText>
                        </HoverButton>
                    )}
                </CardImgContainer>
                <ContentStyle>
                    {!IsMobile() ? (
                        <CardTagsContainer>
                            {educationalSolutionItem.novidade && (
                                <CardTag
                                    bgColor={Colors.Yellow}
                                    textColor={Colors.Blue}
                                    twoCards={educationalSolutionItem.novidade && educationalSolutionItem.proximoLancamento}
                                >
                                    Novidade
                                </CardTag>
                            )}
                            {educationalSolutionItem.proximoLancamento && (
                                <CardTag
                                    bgColor={Colors.Yellow}
                                    textColor={Colors.Blue}
                                    twoCards={educationalSolutionItem.novidade && educationalSolutionItem.proximoLancamento}
                                >
                                    Próximo Lançamento
                                </CardTag>
                            )}
                        </CardTagsContainer>
                    ) : (
                        <MobileCardTagsContainer>
                            {educationalSolutionItem.novidade && (
                                <MobileCardTag
                                    bgColor={Colors.Yellow}
                                    textColor={Colors.Blue}
                                    twoCards={educationalSolutionItem.novidade && educationalSolutionItem.proximoLancamento}
                                >
                                    Novidade
                                </MobileCardTag>
                            )}
                            {educationalSolutionItem.proximoLancamento && (
                                <MobileCardTag
                                    bgColor={Colors.Yellow}
                                    textColor={Colors.Blue}
                                    twoCards={educationalSolutionItem.novidade && educationalSolutionItem.proximoLancamento}
                                >
                                    Próximo Lançamento
                                </MobileCardTag>
                            )}
                        </MobileCardTagsContainer>
                    )}

                    <Title
                        onClick={
                            educationalSolutionItem.title == 'Alfabetização 360°'
                                ? goToDetailPage
                                : usuarioLogado
                                ? educationalSolutionItem.active
                                    ? goToJourney
                                    : goToDetailPage
                                : goToDetailPage
                        }
                    >
                        {educationalSolutionItem.title}
                    </Title>
                    <TextBox>
                        <DescriptionText>{getText()}</DescriptionText>
                    </TextBox>
                </ContentStyle>
                <FooterStyle>
                    {educationalSolutionItem.title !== 'Alfabetização 360°' &&
                    educationalSolutionItem.title !== 'Criatividade e pensamento crítico' ? (
                        <>
                            <PrimaryButton onClick={goToDetailPage}>{'Saiba mais'}</PrimaryButton>
                            <SecondaryButton onClick={goToJourney} isActive={educationalSolutionItem.active}>
                                {educationalSolutionItem.active ? 'Acessar' : 'Em breve'}
                            </SecondaryButton>
                        </>
                    ) : (
                        <>
                            <TerciaryButton onClick={goToDetailPage}>{'Saiba mais'}</TerciaryButton>
                        </>
                    )}
                </FooterStyle>
            </CardStyle>
        </Wrapper>
    );
};

export default HoverCard;
