import React from 'react';
import { MobileMaxWidth } from 'constants/Dimensions';
import { CarouselCustomPaging, CarouselDots } from 'pages/home/components/educationalSolutions/EducationalSolutionsContainer.styled';
import styled from 'styled-components';

const CardsContainerTematicaCarrouselStyled = {
    Container: styled.div`
        padding-left: 104px;

        @media only screen and (max-width: 830px) {
            padding-left: 0px;
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            padding-left: 0;
        }
    `,

    TabsContiner: styled.div`
        display: flex;
        max-width: 100%;

        @media only screen and (max-width: 830px) {
            padding-left: 30px;
        }

        .max-toptip {
            max-width: 280px;
            text-transform: none;
            line-height: 18px;
            font-size: 1.05em;

            @media only screen and (max-width: 280px) {
                max-width: 150px;
                font-size: 12px;
                text-align: center;
            }
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            overflow-x: auto;
            border-bottom: 1px #dedede solid;
            padding: 0 15px;
        }
    `,

    Tab: styled.p<{ active: boolean; visivel: boolean }>`
        border-bottom: 5px ${props => (props.active ? '#E8950D' : 'white')} solid;
        width: fit-content;
        margin-right: 30px;
        color: ${props => (props.active ? '#FFFFFF' : '#b4beca')};
        font-family: 'Barlow';
        font-style: 700;
        font-weight: ${props => (props.active ? '700' : '400')};
        font-size: 20px;
        line-height: 24px;
        padding: 5px;
        letter-spacing: 0.1px;
        cursor: pointer;
        display: ${props => (props.visivel ? 'block' : 'none')};

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            font-size: 12px;
            line-height: 14px;
            // white-space: nowrap;
            margin-right: 8px;
            text-align: center;
        }
    `,

    ScrollContainer: styled.div`
        max-width: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 10px 0;
        overflow-x: hidden;
        
        //transform: translateX(-55px);

        .flip {
            transform: rotate(180deg);
            box-shadow: -4px -2px 10px #888;
        }

        @media only screen and (max-width: 830px) {
            padding: 0;
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            height: 570px;
            width: 100%;
            margin-top: 0px;
        }
    `,

    CarrouselContainer: styled.div`

        max-width: 100%;
        width: 100%;
        height: fit-content;
        padding-bottom: 10px;
    `,

    Scroll: styled.div`
        display: flex;
        overflow-x: auto;
        //white-space: nowrap;
        scroll-behavior: smooth;
        padding-bottom: 30px;
        padding-top: 10px;
        padding-left: 10px;

        @media only screen and (max-width: 830px) {
            padding-left: 0;
        }

        &::-webkit-scrollbar {
            display: none;

            @media only screen and (max-width: ${MobileMaxWidth}px) {
                width: 12px;
                box-sizing: content-box;
                display: inline-block;
            }
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            &::-webkit-scrollbar-thumb {
                background-color: #d8d8d8; /* color of the scroll thumb */
                border-radius: 20px; /* roundness of the scroll thumb */
                margin: 10px;
                border: 4px solid white;
                box-sizing: content-box;
            }

            &::-webkit-scrollbar-track {
                background: transparent; /* color of the tracking area */
            }

            padding-bottom: 0px;
        }
    `,

    ScrollItem: styled.div`
        margin-right: 2em;
    `,

    ArrowButton: styled.img<{ active: boolean }>`
        box-shadow: 4px 2px 10px #888;
        border-radius: 50%;
        mix-blend-mode: multiply;
        margin: 10px;
        margin-right: 20px;
        height: 40px;
        width: 40px;
        cursor: pointer;

        filter: ${props => (props.active ? 'none' : 'grayscale(1)')};
        opacity: ${props => (props.active ? '1' : '0.55')};

        transition: 400ms;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            display: none;
        }
    `,
};

export const mobileCarouselSettings = {
    dots: true,
    infinite: false,
    focusOnSelect: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // eslint-disable-next-line react/display-name
    appendDots: (dots: any) => (
        <CarouselDots>
            <ul style={{ margin: '0px' }}> {dots} </ul>
        </CarouselDots>
    ),
    // eslint-disable-next-line react/display-name
    customPaging: (i: number) => <CarouselCustomPaging />,
};

export default CardsContainerTematicaCarrouselStyled;
